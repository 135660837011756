<template>
    <main id="home-index" class="page">
        <Carousel class="landing" :data="carousel" />
        <section class="container text-center py-5">
            <h1 hidden>广东京禧集团有限公司</h1>
            <h2 class="mb-4">京禧，让生活充满惊喜</h2>
            <p class="lead">
                广东京禧集团有限公司秉承和谐共进的发展理念，与社会各界同仁一起努力，为创造更精彩的生活而奋斗。
            </p>
        </section>
        <section class="nav-card py-5 container">
            <div class="card-deck">
                <div class="card" 
                    v-for="v in business" :key="v.title" 
                    @click="onDetail(v.content)">
                    <img class="card-img-top shadow rounded" alt="配图"
                        :src="require('@a/' + v.cover)">
                    <div class="card-body">
                        <h5 class="card-title">{{ v.title }}</h5>
                        <p class="card-text">{{ v.subtitle }}</p>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import Carousel from '@c/Carousel';
import { business } from '@js/common';

function assets(name) {
    return require('@a/home/' + name);
}

export default {
    name: 'home',
    components: { Carousel },
    data() {
        return {
            carousel: [
                { src: assets('carousel-1.jpg') },
                { src: assets('carousel-2.jpg') },
                { src: assets('carousel-3.jpg') }
            ],
            business
        };
    },
    methods: {
        onDetail(path) {
            this.$router.push({
                name: 'business-detail',
                query: { path }
            });
        }
    }
};
</script>

<style lang="less">
@import '../../style/mixin';

#home-index {
    .landing {
        .carousel-inner {
            &:after {
                .mask;
                background: linear-gradient(black, rgba(0, 0, 0, 0));
                height: 50%;
            }
        }
    }

    .nav-card {
        .card {
            transition-duration: 0.3s;
            transition-property: transform;
            cursor: pointer;
            border-color: transparent;

            .card-body {
                text-align: center;

                .card-title {
                    color: #555;
                    font-weight: 300;
                }

                .card-text {
                    color: #666;
                }
            }

            &:hover {
                transform: translateY(-0.5rem);
            }
        }
    }

    @media (max-width: 767px) {
        .nav-card {
            display: none;
        }
    }
}
</style>