<template>
    <div :id="id" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li v-for="(v, i) in data" 
                :data-target="href" 
                :data-slide-to="i" :class="activeClass(i)"></li>
        </ol>
        <div class="carousel-inner" role="listbox">
            <div class="carousel-item" v-for="(v, i) in data" :class="activeClass(i)">
                <img class="d-block w-100" :src="v.src" :alt="v.alt || v.title" >
                <div class="carousel-caption">
                    <h4 class="title">{{ v.title }}</h4>
                    <p v-if="v.caption" class="caption">{{ v.caption }}</p>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" :href="href" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon"></span>
        </a>
        <a class="carousel-control-next" :href="href" role="button" data-slide="next">
            <span class="carousel-control-next-icon"></span>
        </a>
    </div>
</template>

<script>
export default {
    name: 'Carousel',
    props: {
        id: {
            type: String,
            default: 'carousel-' + Math.round(Math.random() * 100)
        },
        data: { type: Array, required: true },
        active: { type: Number, default: 0 }
    },
    data() {
        return {
            href: '#' + this.id
        };
    },
    methods: {
        activeClass(index) {
            if (this.active === index) return 'active';
        }
    }
};
</script>